/* =========================================================================
   $FLEX - JOURNAL SPECIFIC
   flexbox utilities
   ========================================================================= */

.flex-wrap-reverse {
	flex-wrap: wrap-reverse;
}

.flex-center {
	justify-content: center;
}