.c-site-messages {
	color: #fff;
	background-color: #36454d;
	border-bottom: 3px solid #29363d;

	padding: {
		bottom: 15px;
		top: 15px;
	}

	p {
		line-height: 20px;
		margin-bottom: 0;
	}

	.c-site-messages__content {
		display: inline-block;
		width: 89%;
	}

	.c-site-messages__close-container {
		position: relative;
		text-align: right;
		display: inline-block;
		width: 10%;
	}

	.c-site-messages__close {
		background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D'21'%20height%3D'21'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20aria-labelledby%3D'close'%20role%3D'img'%3E%3Ctitle%20id%3D'title'%3EClose%20button%20icon%3C%2Ftitle%3E%3Cpath%20d%3D'M10.5%2012.621l2.727%202.727%201.06%201.06%202.122-2.12-1.06-1.061L12.62%2010.5l2.727-2.727%201.06-1.06-2.12-2.122-1.061%201.06L10.5%208.38l-2.69-2.69-1.06-1.06L4.629%206.75l1.06%201.06%202.69%202.69-2.69%202.69-1.06%201.06%202.121%202.121%201.06-1.06%202.69-2.69zm0%208.379C4.701%2021%200%2016.299%200%2010.5S4.701%200%2010.5%200%2021%204.701%2021%2010.5%2016.299%2021%2010.5%2021z'%20fill%3D'%23fff'%20fill-rule%3D'evenodd'%2F%3E%3C%2Fsvg%3E"), none;
		background-position: 100% 50%;
		background-repeat: no-repeat;
		background-size: 16px;
		color: #fff;
		padding-right: 20px;

		&:hover {
			text-decoration: underline;
		}
	}
}

.p-link-button {
	background: none;
	border: none;
	cursor: pointer;
}
