/**
 *	Settings
 *	Nature specific article component configuration (overrides configuration from shared-article/10-settings)
*/

// Default setting
@import 'default';

$article--font-family: $context--font-family-primary;

$article--blockquote-motto-color: greyscale(75);

$article--link-color: color('blue');
$article--link-icon-color: str-slice(ie-hex-str(color('blue')), 4);
$article--link-border-color: color('steel');
$article--link-focus-color: color('lemon');
$article--section-title-background-color: greyscale(80);
$article--box-color: color('soft-steel');
$article--important-color: color('orange');
$article--key-step-color: color('blue');
$article--citations-count-color-primary: color('blue');
$article--subject-background-color: color('soft-steel');
$article--recommendation-card-link-color: inherit;
$article--recommendations-border-color: #cedbe0;
$article--recommendations-background-color: #f3f3f3;

$article--cta-button-background-color: color('blue');
$article--cta-button-background-hover-color: white;
$article--cta-button-hover-color: color('blue');
$article--cta-button-icon-color: str-slice(ie-hex-str(white), 4);
$article--cta-button-icon-hover-color: str-slice(ie-hex-str(color('blue')), 4);
$article--cta-button-border-color: color('blue');
$article--cta-button-border-radius: 2px;

$author-list--button-background-color: #ebf1f5;
$author-list--button-text-color: #666;
$author-list--button-hover-background-color: color('blue');
$author-list--button-hover-border-color: transparent;
$author-list--button-border-radius: 20px;

$article--font-sizes: (
	'font-size-1': .813rem,
	'font-size-2': .875rem,
	'font-size-3': 1rem,
	'font-size-4': 1.05rem,
	'font-size-5': 1.125rem,
	'font-size-6': 1.5rem,
	'font-size-7': 1.563rem,
	'font-size-8': 1.75rem,
	'font-size-9': 1.875rem,
	'font-size-10': 2.5rem,
	'font-size-11': 3.125rem,
	'font-size-12': 3.75rem
);

$article--grey-palette: (
	'grey-1': greyscale(0),
	'grey-2': greyscale(20),
	'grey-3': greyscale(25),
	'grey-4': greyscale(30),
	'grey-5': greyscale(45),
	'grey-6': greyscale(70),
	'grey-7': greyscale(80)
);
