.app-access-wall {
	background-color: #f3f3f3;
	font-family: $context--font-family-sans;
	margin-top: 30px;
	// copied over to match front half font treatment
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
}

.app-access-wall__container {
	margin: 0 auto;
	max-width: 520px;
	padding: spacing(48) spacing(16);
}

.app-access-wall__title {
	font-size: 28px;
	line-height: 36px;
	font-weight: 400;
	margin-top: 0;
	margin-bottom: 24px;
	text-align: center;
}

.app-access-wall__box {
	font-size: 16px;
	margin-bottom: spacing(48);
}

.app-access-wall__button-container {
	display: flex;
	justify-content: center;

	&:not(:last-child) {
		margin-bottom: spacing(16);
	}
}

.app-access-wall__button {
	width: 370px;
	display: flex;
	padding: 12px 16px;
	border: 2px solid #069;
	color: #fff;
	background-color: #069;
	text-decoration: none;
	justify-content: space-between;
	align-items: center;
	line-height: 1.3;

	&:hover {
		text-decoration: none;
		background-color: #fff;
		border: 2px solid #069;
		color: #069;
	}
}
.app-access-wall__button--google {
	background-color: #2f72e1;

	img {
		padding: 4px;
	}
}

.app-access-wall__button--orcid {
	background-color: #447405;
}

.app-access-wall__button--primary {
	justify-content: normal;
}

.app-access-wall__button--secondary {
	background-color: #fff;
	color: #069;

	&:hover {
		background-color: #069;
		color: #fff;
	}
}

.app-access-wall__button-label {
	margin-right: 8%;
}

.app-access-wall__icon {
	@include u-icon;
	width: auto;
	height: auto;
	flex: 0 0 auto;
	margin-right: spacing(16);

	&__signup {
		margin-top: spacing(4);
	}
}

.app-access-wall__icon--method {
	background-color: white;
	width: 24px;
	height: 24px;
}

p.app-access-wall__option-separator {
	position: relative;
	max-width: 370px;
	margin: 32px auto;
	text-align: center;
	display: block;

	&::before {
		content: "";
		border-top: 1px solid #aaa;
		display: block;
		position: absolute;
		width: 100%;
		top: 50%;
	}
}

.app-access-wall__option-separator-text {
	padding: 0 16px;
	background: #f3f3f3;
	z-index: 1;
	position: relative;
}
