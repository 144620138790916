/**
 * Bootstrap legacy
 * End-point for legacy content taken from shunter-mosaic
 * Context provided by @springernature/brand-context/nature
 * trimmed down version of shunter-mosaic/resources/css/bootstrap-oscar used for articles via SAR
 */

// globalui/typographic
.serif {
	font-family: $context--font-family-serif;
}

.sans-serif {
	font-family: $context--font-family-sans;
}

$is-nature-branded: false !default;

@if $is-nature-branded == false {
	@font-face {
		font-family: 'npg-stix-regular';
		src: url('/static/fonts/npg-stix-regular.woff2') format('woff2'), url('/static/fonts/npg-stix-regular.woff') format('woff');
		font-display: swap;
	}

	.stix {
		font-family: "npg-stix-regular", "Arial", "Verdana", sans-serif;
	}
}

// globalui/layout
.article-page {
	background: #fff;
}

.composite-layer {
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	transform: translateZ(0);
}
